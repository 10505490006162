/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios from "axios";

const [AUTH_USER, AUTH_PASS] = (
  process.env.NEXT_PUBLIC_BASIC_AUTH || ":"
).split(":");

export const graphqlApiCall = async (props: {
  node: string;
  columns: string[];
  input?: string;
  id?: string;
  conditions?: string;
}) => {
  const { node, columns, id, input, conditions } = props;
  let query: string;
  if (node.startsWith("create") || node.startsWith("update")) {
    query = `mutation { ${node} (input: { ${input} } ) { ${columns.join("\n")} } }`;
  } else if (node.endsWith("Id") && id) {
    if (node.startsWith("delete")) {
      query = `mutation { ${node} (input: { id: "${id}" } ) { ${columns.join("\n")} } }`;
    } else {
      if (node.endsWith("ById"))
        query = `query { ${node} (id: "${id}") { ${columns.join("\n")} } }`;
      else query = `query { ${node} (${id}) { ${columns.join("\n")} } }`;
    }
  } else {
    query = `query { ${node} ${conditions ? `(${conditions})` : ""} { edges { node {${columns.join("\n")} } } } }`;
  }
  const token = `${AUTH_USER}:${AUTH_PASS}`;
  const encodedToken = Buffer.from(token).toString("base64");
  const headers = { Authorization: "Basic " + encodedToken };
  const response = await axios.post(
    "/api/graphql",
    { query: query },
    { headers },
  );
  if (
    node.startsWith("create") ||
    node.startsWith("update") ||
    (node.endsWith("Id") && id)
  ) {
    return response.data.data[node];
  } else {
    if (response.data.data[node]) {
      return response.data.data[node].edges.map(
        (data: { node: any }) => data.node,
      );
    } else {
      return response.data.data[node];
    }
  }
};

// { createUser : (input: { user: {name: "Demo User", email: "", mobileNumber: "6515551235", interested_for: "enterprise"} } ) {  } }
