import React, { Dispatch, SetStateAction } from "react";
import { UserObject } from "src/helpers";

type AppContextType = {
  state: {
    currentUser?: UserObject;
    setShowLoader?: Dispatch<SetStateAction<boolean>>;
  };
};

const AppContext = React.createContext<AppContextType>({ state: {} });

export default AppContext;
