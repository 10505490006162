import { useState, useEffect } from "react";
import Image from "next/image";
import { NavLink } from ".";
import { UserObject } from "src/helpers";
import { userService } from "src/services";
import { useRouter } from "next/router";

const Nav = () => {
  const [user, setUser] = useState<UserObject>();
  const router = useRouter();

  const onLogoClick = () => {
    router.push("/");
  };

  useEffect(() => {
    const subscription = userService.user.subscribe((x) => setUser(x));
    return () => subscription.unsubscribe();
  }, []);

  // only show nav when logged in
  if (!user) return null;

  return (
    <nav className="navbar navbar-expand-lg navbar-light px-3 border-bottom">
      <div
        className="navbar-brand"
        style={{ cursor: "pointer" }}
        onClick={onLogoClick}
      >
        <Image
          src="/images/favicon.png"
          alt="Abillion"
          height={48}
          width={65}
        />
        <span className="fs-4 mx-1 heading">Abillion Trade Analytics</span>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <i className="fa fa-lg fa-angles-left"></i>&nbsp;
      </button>
      <div
        className="offcanvas offcanvas-top"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
            Abillion Trade Analytics
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav ms-auto fs-5">
            <NavLink href="/" exact className="nav-item nav-link">
              Home
            </NavLink>
            {user.is_admin && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Users
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink href="/users" exact className="dropdown-item">
                      Admins
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="/appusers" exact className="dropdown-item">
                      App Users
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            <NavLink href="/hps" exact className="nav-item nav-link">
              HPS
            </NavLink>
            <NavLink href="/fno" exact className="nav-item nav-link">
              F&O
            </NavLink>
            {user.is_admin && (
              <NavLink href="/control-hub" exact className="nav-item nav-link">
                Control Hub
              </NavLink>
            )}
            <button
              onClick={userService.logout}
              className="btn btn-light nav-item"
            >
              <i className="fa fa-lg fa-arrow-right-from-bracket"></i>&nbsp;
              Logout
            </button>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export { Nav };
